<template>
    <div style="min-height: calc(100vh - 158px);" class="card">
        <div style="background: #DBE7F2" class="p-2">
            <TitleButton
                :showBtn="false"
                :showAddNew="true"
                btnTitle="Add New"
                title="Payment Voucher List"
                @onClickAddNewButton="onClickAddNewButton"
            />

            <div class="row match-height mt-1">
                <div class="col-12 col-lg-8 col-xl-9 col-xxl-7 mt-2">
                    <DateQuerySetter
                        @onClickGo="getPayments"
                    />
                </div>
            </div>
        </div>

        <ListTable />
        <div class="mb-2"></div>

        <div class="px-2 position-absolute bottom-0">
            <Pagination
                :offset="offset"
                @onPageChange="onPageChange"
                ref="pagination"
            />
        </div>
        <Loader v-if="loading"/>
    </div>
</template>

<script>
import handleJournal from '@/services/modules/journal'
import { inject } from 'vue'
import { computed } from 'vue'
import {mapMutations} from 'vuex'

import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/company/payment/ListTable'
import Pagination from '@/components/atom/Pagination'
import Loader from '@/components/atom/LoaderComponent'

export default {
    name: 'JournalList',

    data: () => ({
        offset: 20
    }),

    components: {
        TitleButton,
        DateQuerySetter,
        ListTable,
        Pagination,
        Loader
    },
    computed: {
        companyId() {
            return this.$route.params.companyId
        },
        start() {
            return this.$route.query.start
        },
        end() {
            return this.$route.query.end
        },
        page() {
            return this.$route.query.page
        },
    },
    methods: {
        ...mapMutations({
            setJournals: 'journal/setJournals',
            setPaginationData: 'setPaginationData',
            resetPagination: 'resetPagination'
        }),
        onClickAddNewButton() {
            this.$router.push({name: 'CreatePayment',
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId
                },
                query: {
                    start: this.start,
                    end: this.end
                }
            })
        },

        async getPayments() {
            const query = this.getQuery()
           // return;
            try {
                this.loading = true
                let res = await this.fetchJournals(query)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.setJournals(res.data.data)
                    if(res.data.data.length > 0) {
                        this.setPagination(res.data)
                    }
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        setPagination(data) {
            this.setPaginationData({
                records: data.total,
                perPage: data.per_page,
                currentPage: data.current_page,
            })
        },

        onPageChange(page) {
            let routeQuery = Object.assign({}, this.$route.query)
            routeQuery.page = page
            this.$router.push({path: this.$route.path, query: routeQuery})
            setTimeout(() => {
                this.getPayments()
            },100)
        },

        getQuery () {
            let query = '?company_id=' + this.companyId
            if(!this.start && !this.end) return query
            if(this.start) query += '&start_date='+this.start
            if(this.end) query += '&end_date='+this.end
            query += '&offset='+this.offset
            if(this.page) query += '&page='+this.page
            query += '&voucher_type=payment_voucher'
            return query
        },
    },

    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        // const store = useStore()

        const {
            fetchJournals,
            loading
        } = handleJournal()

        return {
            journals: computed(() => store.state.journal.journals),
            fetchJournals,
            loading,
            showError,
            showSuccess
        }
    },

    mounted() {
        this.getPayments()
    }
}
</script>
